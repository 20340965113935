(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/navigation-menu-v2-dialog/assets/javascripts/navigation-menu-v2-dialog.js') >= 0) return;  svs.modules.push('/components/accountservices/navigation-menu-v2-dialog/assets/javascripts/navigation-menu-v2-dialog.js');

'use strict';

function menuDialog() {
  this.showDialog = function (message, querySelector) {
    const dialog = new svs.ui.dialog.Contextual({
      branding: svs.ui.dialog.branding.NAVY,
      attachTo: document.querySelector(querySelector),
      zIndex: 1069,
      title: ' ',
      message: [{
        type: svs.ui.dialog.message.TEXT,
        text: message
      }]
    });
    svs.ui.dialog.api.add(dialog);
    $('.dialog-close-outside-click').css({
      zIndex: 1070
    });
  };
}
setGlobal('svs.components.accountservices.navigationMenuV2Dialog', menuDialog());

 })(window);